(function () {
    'use strict';

    angular.module("aerosApp")
        .factory('FiberGroupService', FiberGroupService);

    FiberGroupService.$inject = ['aerosApi'];

    function FiberGroupService(aerosApi) {

        var service = {
            selectedFiberGroups: [],
            getWaveLengthsBySubFiberType: getWaveLengthsBySubFiberType,
            getSimpleFiberTypes: getSimpleFiberTypes,
            unmarkFiberGroupFromDelete: unmarkFiberGroupFromDelete,
            ADD_ACTION: "ADD_ACTION",
            EDIT_ACTION: "EDIT_ACTION"
        };

        function getSimpleFiberTypes() {
            return aerosApi.getSimpleFiberTypes();
        }

        function getWaveLengthsBySubFiberType(fiberTypeStructure) {
            var transformedObj = {};
            angular.forEach(fiberTypeStructure, function (networkTypeElement, networkTypeKey) {
                transformedObj[networkTypeKey] = {};
                angular.forEach(networkTypeElement, function (fiberTypeElement, fiberTypeKey) {
                    transformedObj[networkTypeKey][fiberTypeKey] = {};
                    angular.forEach(fiberTypeElement, function (fiberSubTypeElement, fiberSubTypeKey) {
                        if (angular.isObject(fiberSubTypeElement)) {
                            angular.forEach(fiberSubTypeElement, function (waveLengthElement, waveLengthKey) {
                                if (!transformedObj[networkTypeKey][fiberTypeKey][waveLengthKey.substring(1)]) {
                                    transformedObj[networkTypeKey][fiberTypeKey][waveLengthKey.substring(1)] = {};
                                }

                                transformedObj[networkTypeKey][fiberTypeKey][waveLengthKey.substring(1)][fiberSubTypeKey] = waveLengthElement;
                            });
                        }
                    });
                });
            });

            return transformedObj;
        }

        function unmarkFiberGroupFromDelete(fg) {
            return aerosApi.unmarkFiberGroupFromDelete(fg.orgId, fg.projectId, fg.fiberGroupId);
        }

        return service;
    }
})();